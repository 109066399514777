import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {mediaBaseURL} from "../../../utils/constant";
import {checkData} from "../../../helper";
import HtmlParser from "react-html-parser";

function GrowthPlane({data}) {
    return (

        <section className="tekrevol_gray_bg p-100">
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={10} xl={10} xxl={10}>
                        <div className="example" data-text="Development">
                            <h2>{checkData(data, 'growth_plane_title')}</h2>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-70">
                    <Col xs={12} md={5} lg={5} xl={5} xxl={5} className="text-center order-2 order-md-1">
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'growth_plane_image')}`}
                            className="img-fluid"
                            alt={checkData(data, 'growth_plane_title')}/>
                    </Col>
                    <Col xs={12} md={7} lg={6} xl={6} xxl={6} className="order-1 order-md-2 offset-lg-1">
                        <div>{HtmlParser(checkData(data, 'growth_plane_description'))}</div>
                    </Col>


                </Row>
            </Container>
        </section>

    );
}

export default GrowthPlane;